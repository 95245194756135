/*
    离场结算账单详情 - 押金类tab
*/
<template>
  <div>
    <div class="button-panel">
    </div>
    <a-table
      rowKey="id"
      :columns="columns"
      :dataSource="depositList"
      :pagination="false"
      :rowClassName="handleTableRowClass"
    >
      <div slot="action" slot-scope="text, record">
        <span v-if="record.id !== -1" class="blueColor">
          <a @click="handleEdit(record)">编辑</a>&nbsp;
        </span>
      </div>
    </a-table>
    <a-modal
      :title="isNew ? '新增押金' : '编辑押金'"
      :width="450"
      :visible="showModal"
      @ok="onSubmit"
      @cancel="onCancel"
      destroyOnClose
    >
      <a-form :form="form" layout="inline" :labelCol="formLabelCol" :wrapperCol="formInputCol">
        <a-form-item label="押金类型">
          <a-select
            v-if="isNew"
            placeholder="选择一个选项"
            v-decorator="[
              'typeId',
              {
                rules: [{ required: true, message: '请输入押金类型' }],
                initialValue: depositItem.type, 
              }
            ]"
            @change="val => { handleDepositItemTypeChange(val) }"
          >
            <a-select-option
              v-for="type in this.depositTypeList"
              :key="type.typeId"
            >{{ type.typeName }}</a-select-option>
          </a-select>
          <div v-else>{{ getTypeNameById(depositItem.typeId) }}</div>
        </a-form-item>
        <a-form-item label="已收金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
                          'payedAmountYuan',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue: depositItem.payedAmountYuan
                          }
                        ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="罚没金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
                          'fineAmountYuan',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue: depositItem.fineAmountYuan 
                          }
                        ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="返还金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
                          'returnAmountYuan',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue: depositItem.returnAmountYuan 
                          }
                        ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: depositItem.remark 
              }
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { decimalTwoDigit } from "@/utils/regex";
  

let newDepositItem = {
  id: null,
  typeId: null,
  name: null, // 押金类型名称，仅在其他类型时可用
  payedAmountYuan: null,
  fineAmountYuan: null,
  needPayAmountYuan: null,
  returnAmountYuan: null,
  remark: null,
};

export default {
  name: "SettlementDetailDeposit",
  props: {
    billId: {
      type: [String, Number],
    },
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    contractId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 表格数据
      depositList: [],
      // 表格中的列。没有根据status去判断查看或编辑，通过隐藏action让查看态不可编辑(注意数组会被filter)
      columns: [
        {
          title: "押金类型",
          dataIndex: "typeId",
          key: "typeId",
          customRender: (text, row, index) => {
            return row.name;
          },
        },
        {
          title: "已收金额（元）",
          dataIndex: "payedAmountYuan",
          key: "payedAmountYuan",
        },
        {
          title: "罚没金额（元）",
          dataIndex: "fineAmountYuan",
          key: "fineAmountYuan",
        },
        {
          title: "应收金额（元）",
          dataIndex: "needPayAmountYuan",
          key: "needPayAmountYuan",
        },
        {
          title: "返还金额（元）",
          dataIndex: "returnAmountYuan",
          key: "returnAmountYuan",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ].filter((c) => this.status === "edit" || c.dataIndex !== "action"),
      showModal: false,
      // 当前正在编辑的押金项
      depositItem: newDepositItem,
      // 新增（true）/编辑（false）
      isNew: null,
      // 编辑金额时的表单
      form: this.$form.createForm(this),
      // 押金种类
      depositTypeList: [],
      // 两位小数的正则
      decimalTwoDigit,
      // 表单label的样式
      formLabelCol: {
        style: {
          textAlign: "right",
          width: "110px",
          margin: "5px",
        },
      },
      // 表单input的样式
      formInputCol: {
        style: {
          width: "250px",
          margin: "5px",
        },
      },
    };
  },
  methods: {
    refetch() {
      // 押金列表
      axios({
        url:   this.$baseUrl + "departure/cost/deposit/list",
        method: "GET",
        params: {
          billId: this.billId,
          contractId: this.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.depositList = [ ...res.data.obj.departureCostDeposits || [], {
            id: -1,
            name: '总计（元）',
            payedAmountYuan: res.data.obj.payedAmountTotalYuan,
            fineAmountYuan: res.data.obj.fineAmountTotalYuan,
            needPayAmountYuan: res.data.obj.needPayAmountTotalYuan,
            returnAmountYuan: res.data.obj.returnAmountTotalYuan,
          }];
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    // 新增金额
    handleAdd() {
      this.depositItem = Object.assign({}, newDepositItem);
      this.isNew = true;
      this.showModal = true;
    },
    // 编辑金额
    handleEdit(record) {
      this.depositItem = record;
      this.isNew = false;
      this.showModal = true;
    },
    // 删除金额
    handleDelete(record) {
      axios({
        url:   this.$baseUrl + "departure/cost/deposit/delete",
        method: "POST",
        data: {
          id: record.id,
          contractId: this.contractId,
          typeId: record.typeId
        },
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("删除成功");
          this.refetch();
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    // 新增/编辑的提交
    onSubmit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 这里判断“应收金额”和“返还金额”不能至少一个是0
          if (this.isNew) {
            axios({
              url:   this.$baseUrl + "departure/cost/deposit/add",
              method: "POST",
              data: {
                billId: this.billId,
                contractId: this.contractId,
                typeId: values.typeId,
                name: values.name,
                payedAmount: values.payedAmountYuan,
                fineAmount: values.fineAmountYuan,
                needPayAmount: values.needPayAmountYuan,
                returnAmount: values.returnAmountYuan,
                remark: values.remark,
              },
            }).then((res) => {
              if (res.data.success) {
                this.$message.success("添加成功");
                this.refetch();
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
          } else {
            // edit
            axios({
              url:   this.$baseUrl + "departure/cost/deposit/modify",
              method: "POST",
              data: {
                id: this.depositItem.id,
                billId: this.billId,
                contractId: this.contractId,
                typeId: this.depositItem.typeId,
                name: values.name,
                payedAmount: values.payedAmountYuan,
                fineAmount: values.fineAmountYuan,
                needPayAmount: values.needPayAmountYuan,
                returnAmount: values.returnAmountYuan,
                remark: values.remark,
              },
            }).then((res) => {
              if (res.data.success) {
                this.$message.success("编辑成功");
                this.refetch();
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
          }
          this.showModal = false;
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.showModal = false;
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (record.name.includes("总计")) {
        return "total-bold";
      }
    },
    // 根据押金类型id查找name
    getTypeNameById(id) {
      const type = this.depositTypeList.find((t) => t.typeId === id);
      return !!type ? type.typeName : "";
    },
    canEditTypeName(id) {
      const type = this.depositTypeList.find((t) => t.typeId === id);
      return type && type.enableModifyName === "Y";
    },
    handleDepositItemTypeChange(value) {
      this.depositItem.typeId = value;
    },
  },
  created() {
    // 押金种类
    axios({
      url:   this.$baseUrl + "departure/cost/type/list",
      method: "GET",
      params: {
        type: 1, // 1:押金类， 2:赔偿类， 3:返还类
      },
    }).then((res) => {
      if (res.data.success) {
        this.depositTypeList = res.data.rows;
      } else {
        this.$message.error(res.data.returnMsg);
      }
    });
    this.refetch();
  },
};
</script>

<style lang="less" scoped>
.nob {
  border-radius: 0 !important;
}
.bold {
  font-weight: bold;
}
</style>