var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DescriptionList',{attrs:{"title":"离场相关费用","size":"large"}},[_c('div',{staticClass:"button-panel"},[(_vm.status !== 'view')?_c('a-button',{attrs:{"icon":"plus"},on:{"click":_vm.handleAddCharges}},[_vm._v("新增")]):_vm._e()],1),_c('a-table',{staticClass:"table-wrapper",attrs:{"rowKey":"id","columns":_vm.chargesColumns,"dataSource":_vm.chargesList,"pagination":false,"rowClassName":_vm.handleTableRowClass},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{},[(record.id !== -1)?_c('span',{staticClass:"blueColor"},[_c('a-popconfirm',{attrs:{"placement":"bottomRight","title":"确认删除吗？","okText":"Yes","cancelText":"No"},on:{"confirm":function($event){return _vm.handleDeleteCharges(record)}}},[_c('a',[_vm._v("删除")])])],1):_vm._e()])}}])})],1),_c('a-modal',{attrs:{"title":`${_vm.isNew ? '新增' : '编辑'}${ '新增费用'}`,"width":450,"visible":_vm.showModal,"destroyOnClose":""},on:{"ok":_vm.onSubmit,"cancel":_vm.onCancel}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"inline","labelCol":_vm.formLabelCol,"wrapperCol":_vm.formInputCol}},[_c('a-form-item',{attrs:{"label":"费用类型"}},[(_vm.isNew)?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [{ required: true, message: '请输入类型' }],
              initialValue: _vm.chargesItem.type, 
            }
          ]),expression:"[\n            'type',\n            {\n              rules: [{ required: true, message: '请输入类型' }],\n              initialValue: chargesItem.type, \n            }\n          ]"}],attrs:{"placeholder":"选择一个选项"}},_vm._l(((_vm.chargeTypeList)),function(type){return _c('a-select-option',{key:type.typeId},[_vm._v(_vm._s(type.typeName))])}),1):_c('div',[_vm._v(_vm._s(_vm.chargesItem.type))])],1),_c('a-form-item',{attrs:{"label":"金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'amount',
                        {
                          rules: [
                            {
                              pattern: _vm.decimalTwoDigit,
                              required: true,
                              message: '不支持的格式'
                            }
                          ],
                          initialValue: _vm.chargesItem.amount 
                        }
                      ]),expression:"[\n                        'amount',\n                        {\n                          rules: [\n                            {\n                              pattern: decimalTwoDigit,\n                              required: true,\n                              message: '不支持的格式'\n                            }\n                          ],\n                          initialValue: chargesItem.amount \n                        }\n                      ]"}],attrs:{"placeholder":"请输入金额"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              rules: [{ required: true, message: '请输入备注' }],
              initialValue: _vm.chargesItem.remark 
            }
          ]),expression:"[\n            'remark',\n            {\n              rules: [{ required: true, message: '请输入备注' }],\n              initialValue: chargesItem.remark \n            }\n          ]"}],staticClass:"nob",attrs:{"placeholder":"请输入备注"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }