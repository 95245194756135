/*
    离场结算账单详情 - 总计tab
*/
<template>
  <div>
    <a-spin :spinning="dataLoading">
      <DescriptionList title="收款类金额" size="large">
        <a-table
          rowKey="id"
          class="table-wrapper"
          :columns="columns"
          :dataSource="receiveList"
          :pagination="false"
          :rowClassName="handleTableRowClass"
        />
      </DescriptionList>
      <a-divider dashed />
      <DescriptionList title="退款类金额" size="large">
        <a-table
          rowKey="id"
          class="table-wrapper"
          :columns="columns"
          :dataSource="returnList"
          :pagination="false"
          :rowClassName="handleTableRowClass"
        />
      </DescriptionList>
      <a-divider dashed />
      <div class="desc-title">账单总计：{{ totalAmount }}</div>
    </a-spin>
  </div>
</template>

<script>
import _get from "lodash/get";
import DescriptionList from "@/components/DescriptionList";
import { decimalTwoDigit } from "@/utils/regex";
  

export default {
  name: "SettlementDetailSummary",
  components: {
    DescriptionList,
  },
  props: {
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    billId: {
      type: [String, Number],
    },
    contractId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 收款类表格数据
      receiveList: [],
      // 退款类表格数据
      returnList: [],
      // 表格中的列
      columns: [
        {
          title: "金额类型",
          dataIndex: "typeName",
          key: "typeName",
        },
        {
          title: "金额（元）",
          align: "right",
          dataIndex: "totalAmountYuan",
          key: "totalAmountYuan",
        },
      ],
      // 最后的总计
      totalAmount: null,
      // 数据加载
      dataLoading: false,
    };
  },
  methods: {
    refetch() {
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "departure/cost/list",
        method: "GET",
        params: {
          billId: this.billId,
          contractId: this.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          // 收款类列表
          /* 这么做是为了避免后端传来的id为空导致的渲染问题 */
          let listData = _get(res, "data.obj.billCostReceives") || [];
          listData.map((i) => (i.id = i.id || Math.random()));
          this.receiveList = [
            ...listData,
            {
              id: -1,
              typeName: "总计（元）",
              totalAmountYuan: _get(res, "data.obj.totalAmountReceiveYuan"),
              remark: "",
            },
          ];
          // 退款类列表
          let listData2 = _get(res, "data.obj.billCostReturns") || [];
          listData2.map((i) => (i.id = i.id || Math.random()));
          this.returnList = [
            ...listData2,
            {
              id: -1,
              typeName: "总计（元）",
              totalAmountYuan: _get(res, "data.obj.totalAmountReturnYuan"),
              remark: "",
            },
          ];
          // 退款类展示负数
          this.returnList.map((i) => {
            const amount = i.totalAmountYuan || "";
            if (amount.length > 0 && amount !== "0.00" && amount !== "0") {
              i.totalAmountYuan = `-${amount}`;
            }
          });
          this.totalAmount = _get(res, "data.obj.totalAmountYuan");
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.dataLoading = false;
      });
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (record.typeName && record.typeName.includes("总计")) {
        return "total-bold";
      }
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<style scoped>
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.nob {
  border-radius: 0 !important;
}
.table-wrapper {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  width: 600px;
}
.desc-title {
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
</style>