/*
    离场结算账单详情 - 房租账单tab
*/
<template>
  <div>
    <a-spin :spinning="dataLoading">
      <div>
        <div class="field-panel">
          <div class="item text">
            房租计算结束日期：{{ leaveDate.rentalEndDate }}
          </div>
          <div class="item text">调整备注：{{ leaveDate.remark }}</div>
          <a-button
            v-if="status !== 'view'"
            type="primary"
            class="item"
            @click="handleDateChange"
            >修改止租日</a-button
          >
        </div>
      </div>
      <a-divider dashed />
      <div>
        <DescriptionList size="large">
          <div class="field-panel">
            <div style="margin-top: 16px; flex: 1">
              <Description term="商户应付房租(负数表示退款)">
                <label>{{ totalAmountNeeded }}</label>
              </Description>
            </div>
            <a-button
              v-if="false"
              icon="plus"
              @click="handleAddRent"
              >新增</a-button
            >
          </div>
          <a-table
            rowKey="billId"
            class="table-wrapper"
            :columns="billColumns"
            :dataSource="rentList"
            :pagination="false"
            :rowClassName="handleTableRowClass"
          >
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span v-if="record.billId !== -1">
                  {{ moment(record.cycleStartDate).format("YYYY-MM-DD") }} ~
                  {{ moment(record.cycleEndDate).format("YYYY-MM-DD") }}
                </span>
                <span v-else>总计（元）</span>
              </template>
            </span>
            <div slot="action" slot-scope="text, record">
              <span v-if="false" class="blueColor">
                <a-popconfirm
                  placement="bottomRight"
                  title="确认删除吗？"
                  @confirm="handleDelete(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </a-table>
        </DescriptionList>
      </div>
      <a-divider dashed />
      <div>
        <DescriptionList title="房租账单记录" size="large">
          <a-table
            rowKey="billId"
            class="table-wrapper"
            :columns="recordColumns"
            :dataSource="recordList"
            :pagination="false"
            :rowClassName="handleTableRowClass"
          >
            <div slot="action" slot-scope="text, record">
              <a v-if="record.billId !== -1" @click="handleViewBill(record)"
                >查看</a
              >&nbsp;
            </div>
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span v-if="record.billId !== -1">
                  {{ moment(record.cycleStartDate).format("YYYY-MM-DD") }} ~
                  {{ moment(record.cycleEndDate).format("YYYY-MM-DD") }}
                </span>
              </template>
            </span>
          </a-table>
        </DescriptionList>
      </div>
    </a-spin>
    <a-modal
      title="修改止租日"
      :width="450"
      :visible="showDateChangeModal"
      @ok="onSubmitDate"
      @cancel="onCancelDate"
      destroyOnClose
    >
      <a-form :form="form">
        <a-form-item label="房租计算结束日期">
          <a-date-picker
            style="width: 100%"
            :initialValue="moment(leaveDate.date, 'YYYY-MM-DD')"
            v-decorator="[
              'rentalEndDate',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
                initialValue: leaveDate.rentalEndDate,
              },
            ]"
            placeholder="请选择"
          />
        </a-form-item>
        <a-form-item label="调整备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: leaveDate.remark,
              },
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      title="新增"
      :width="450"
      :visible="showAddRentModal"
      @ok="onSubmitAddRent"
      @cancel="onCancelAddRent"
      destroyOnClose
    >
      <a-form :form="addRentForm">
        <a-form-item label="房租已付金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
              'amount',
              {
                rules: [
                  {
                    pattern: decimalTwoDigit,
                    required: true,
                    message: '不支持的格式',
                  },
                ],
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="房租周期">
          <a-range-picker
            v-decorator="[
              'rentCycle',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
              },
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 弹出式查看账单 -->
    <a-modal
      title="查看账单"
      :width="'80vw'"
      :visible="showBillDetail"
      @cancel="onCloseBill"
      :footer="null"
      :bodyStyle="{ height: '800px', overflowY: 'auto' }"
      destroyOnClose
    >
      <finRentDetail :id="historyBillId" />
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { decimalTwoDigit } from "@/utils/regex";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import finRentDetail from "../financeBailDetail/finRentDetail";
  

// 房租账单记录表格的列
const allRecordColumns = [
  {
    title: "账单ID",
    dataIndex: "billCode",
    key: "billCode",
  },
  {
    title: "账单周期",
    dataIndex: "cycle",
    scopedSlots: { customRender: "cycle" },
  },
  {
    title: "账单状态",
    dataIndex: "billStatusName",
    key: "billStatusName",
  },
  {
    title: "支付状态",
    dataIndex: "payStatusName",
    key: "payStatusName",
  },
  {
    title: "账单总金额",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
  {
    title: "已支付金额",
    dataIndex: "payedAmount",
    key: "payedAmount",
  },
  {
    title: "已确认到账金额",
    dataIndex: "confirmArrivalAmount",
    key: "confirmArrivalAmount",
  },
  {
    title: "应收/退款金额",
    dataIndex: "needPayAmount",
    key: "needPayAmount",
  },
  {
    title: "未确认到账金额",
    dataIndex: "notConfirmArrivalAmount",
    key: "notConfirmArrivalAmount",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "SettlementDetailRent",
  components: {
    DescriptionList,
    Description,
    finRentDetail,
  },
  props: {
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    // 离场账单id
    billId: {
      type: [String, Number],
    },
    // 合同id
    contractId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 当月房租表格数据
      rentList: [],
      // 表格中的列
      billColumns: [
        {
          title: "房租周期",
          dataIndex: "cycle",
          scopedSlots: { customRender: "cycle" },
        },
        {
          title: "商户需缴金额（元）",
          dataIndex: "totalNeedPayAmount",
          key: "totalNeedPayAmount",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      // 是否展示修改止租日modal
      showDateChangeModal: false,
      // 编辑止租日时的表单
      form: this.$form.createForm(this),
      // 增加房租的表单
      addRentForm: this.$form.createForm(this),
      // 两位小数的正则
      decimalTwoDigit,
      // 历史账单记录表格的列
      recordColumns:
        this.status === "view"
          ? allRecordColumns.filter((c) => c.dataIndex !== "action")
          : allRecordColumns,
      // 历史账单记录
      recordList: [],
      // 修改止租日的数据
      leaveDate: {},
      // 是否展示弹出式账单详情
      showBillDetail: false,
      // 弹出式帐单详情当前打开的账单id
      historyBillId: null,
      // UI加载
      dataLoading: false,
      // 商户仍需缴纳房租金额
      totalAmountNeeded: null,
      // 是否展示增加房租modal
      showAddRentModal: false,
    };
  },
  methods: {
    moment,
    refetch() {
      this.dataLoading = true;
      let dateCompleted = false;
      let listCompleted = false;
      let recordCompleted = false;
      // 结束日期和备注
      axios({
        url:   this.$baseUrl + "contract/getContractDate",
        method: "GET",
        params: {
          contractId: this.contractId,
          type: "rental_end_date",
        },
      }).then((res) => {
        if (res.data.success) {
          this.leaveDate = res.data.obj;
        } else {
          this.$message.error(res.data.returnMsg);
        }
        dateCompleted = true;
        this.dataLoading = !(dateCompleted && listCompleted && recordCompleted);
      });
      // 当月明细
      axios({
        url:   this.$baseUrl + "departure/getRentBillDetail",
        method: "GET",
        params: {
          contractId: this.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          let templist = Object.assign([], res.data.obj.rows);
          templist.map((i) => (i.billId = i.billId || Math.random()));
          this.rentList = [
            ...(templist || []),
            {
              billId: -1,
              totalAmount: res.data.obj.totalAmount,
            },
          ];
          this.totalAmountNeeded = res.data.obj.totalNeedPayAmount;
        } else {
          this.$message.error(res.data.returnMsg);
        }
        listCompleted = true;
        this.dataLoading = !(dateCompleted && listCompleted && recordCompleted);
      });

      // 历史账单
      axios({
        url:   this.$baseUrl + "departure/historyBillList",
        method: "GET",
        params: {
          contractId: this.contractId,
          billType: 4, // 4-房租账单 5-经营费用账单
        },
      }).then((res) => {
        if (res.data.success) {
          this.recordList = [
            ...(res.data.obj.rows || []),
            {
              billId: -1,
              billCode: "总计（元）",
              cycleStartTime: null,
              cycleEndTime: null,
              totalAmount: res.data.obj.totalAmount,
              payedAmount: res.data.obj.totalPayedAmount,
              confirmArrivalAmount: res.data.obj.totalConfirmArrivalAmount,
              needPayAmount: res.data.obj.totalNeedPayAmount,
              notConfirmArrivalAmount:
                res.data.obj.totalNotConfirmArrivalAmount,
            },
          ];
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.totalAmountNeeded = res.data.obj.totalNeedPayAmount;
        recordCompleted = true;
        this.dataLoading = !(dateCompleted && listCompleted && recordCompleted);
      });
    },
    handleViewBill(record) {
      this.historyBillId = record.billId;
      this.showBillDetail = true;
    },
    handleDateChange() {
      // 修改离场日期
      this.showDateChangeModal = true;
    },
    onSubmitDate() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "contract/modifyRentEndDate",
            method: "POST",
            data: {
              id: this.contractId,
              rentalEndDate: values.rentalEndDate,
              remark: values.remark,
            },
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("修改成功");
              this.refetch();
            } else {
              this.$message.error(res.data.returnMsg);
            }
          });

          this.showDateChangeModal = false;
        }
      });
    },
    onCancelDate() {
      this.showDateChangeModal = false;
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (
        (record.billCode && record.billCode.includes("总计")) ||
        (record.billId && record.billId === -1)
      ) {
        return "total-bold";
      }
    },
    handleAddRent() {
      this.showAddRentModal = true;
    },
    onCancelAddRent() {
      this.showAddRentModal = false;
    },
    handleDelete(record) {
      if (record.departureRentDetailId != null) {
        axios({
          url:   this.$baseUrl + `departure/deleteRentDetail?id=${ record.departureRentDetailId }`,
          method: "POST",
        }).then((res) => {
          if (res.data.success) {
            this.$message.success("删除成功");
            this.refetch();
          } else {
            this.$message.error(res.data.returnMsg);
          }
        });
      }
    },
    onSubmitAddRent() {
      this.addRentForm.validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "departure/addRentDetail",
            method: "POST",
            data: {
              departureBillId: this.billId,
              startDate: values.rentCycle[0],
              endDate: values.rentCycle[1],
              amount: values.amount,
              remark: values.remark,
            },
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("新增成功");
              this.showAddRentModal = false;
              this.refetch();
            } else {
              this.$message.error(res.data.returnMsg);
            }
          });

          this.showDateChangeModal = false;
        }
      });
    },
    onCloseBill() {
      this.refetch();
      this.showBillDetail = false;
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<style lang="less" scoped>
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.field-panel {
  display: flex;
  margin: 12px;
  align-items: center;

  .text {
    width: 300px;
    line-height: 40px;
  }
  .item {
    margin: 8px;
  }
}
.nob {
  border-radius: 0 !important;
}
.desc-title {
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
.table-wrapper {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
</style>