/*
    离场结算账单详情 - 经营费用账单tab
*/
<template>
  <div>
    <div class="desc-title">经营费用仍需缴纳金额：{{ amountNeed }}</div>
    <a-spin :spinning="operateListLoading">
      <div v-if="operateBillType === 'fix'">
        <div class="field-panel">
          <div class="item text">实际离场日期：{{ leaveDate.actualDepartureDate }}</div>
          <div class="item text">调整备注：{{ leaveDate.remark }}</div>
          <a-button
            v-if="status !== 'view'"
            type="primary"
            class="item"
            @click="handleDateChange"
          >修改离场日</a-button>
        </div>
      </div>
      <a-divider dashed />
      <div v-if="operateBillType === 'fix'">
        <DescriptionList title="当月经营费用账单" size="large">
          <div v-if="operateBillStatus === 2" class="cannot-edit">此账单已发送，不能修改</div>
          <a-table
            rowKey="id"
            class="table-wrapper"
            :columns="billColumns"
            :dataSource="operateList"
            :pagination="false"
            :rowClassName="handleTableRowClass"
          >
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span
                  v-if="record.id !== -1"
                >{{ `${moment(record.cycleStartTimeLg).format("YYYY-MM-DD")} ~ ${moment(record.cycleEndTimeLg).format("YYYY-MM-DD")}` }}</span>
              </template>
            </span>
            <div slot="action" slot-scope="text, record">
              <span
                v-if="record.isAdjust === 'Y' && operateBillStatus !== 2 && record.id !== -1"
                class="blueColor"
              >
                <!-- <a @click="handleEdit(record)">编辑</a>&nbsp; -->
                <a-popconfirm
                  placement="bottomRight"
                  title="确认删除吗？"
                  @confirm="handleDelete(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </a-table>
        </DescriptionList>
      </div>
      <a-divider v-if="operateBillType === 'fix'" dashed />

      <div>
        <DescriptionList title="经营费用账单记录" size="large">
          <a-table
            rowKey="billId"
            class="table-wrapper margin-top"
            :columns="recordColumns"
            :dataSource="recordList"
            :pagination="false"
            :rowClassName="handleTableRowClass"
          >
            <div slot="action" slot-scope="text, record">
              <span class="blueColor">
                <a @click="handleViewBill(record)">查看</a>
              </span>
            </div>
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span>{{ `${moment(record.cycleStartDate).format("YYYY-MM-DD")} ~ ${moment(record.cycleEndDate).format("YYYY-MM-DD")}` }}</span>
              </template>
            </span>
          </a-table>
        </DescriptionList>
      </div>
    </a-spin>
    <a-modal
      title="修改离场日"
      :width="450"
      :visible="showDateChangeModal"
      @ok="onSubmitDate"
      @cancel="onCancelDate"
      destroyOnClose
    >
      <a-form :form="dateForm">
        <a-form-item label="实际离场日期">
          <a-date-picker
            style="width: 100%"
            :initialValue="moment(leaveDate.date, 'YYYY-MM-DD')"
            v-decorator="[
                            'actualDepartureDate',
                            {
                              rules: [ { required: true, message: '请输入日期' } ],
                              initialValue: leaveDate.actualDepartureDate
                            }
                          ]"
            placeholder="请选择"
          />
        </a-form-item>
        <a-form-item label="调整备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: leaveDate.remark 
              }
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :title="isNew ? '新增金额' : '编辑金额'"
      :width="450"
      :visible="showOperateModal"
      @ok="onSubmit"
      @cancel="onCancel"
      destroyOnClose
    >
      <a-form :form="operateForm">
        <a-form-item label="费用类型">
          <a-select
            placeholder="选择一个选项"
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请输入类型' }],
                initialValue: operateItem.type, 
              }
            ]"
          >
            <a-select-option
              v-for="type in this.operateTypeList"
              :key="type.typeId"
            >{{ type.typeName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="应收金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
                          'amount',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue: operateItem.amount 
                          }
                        ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: operateItem.remark 
              }
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 弹出式查看账单 -->
    <a-modal
      title="查看账单"
      :width="'80vw'"
      :visible="showBillDetail"
      @cancel="onCloseBill"
      :footer="null"
      :bodyStyle="{ height: '800px', overflowY: 'auto'}"
      destroyOnClose
    >
      <finOperateDetail :id="historyBillId" />
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { decimalTwoDigit } from "@/utils/regex";
import DescriptionList from "@/components/DescriptionList";
import finOperateDetail from "../financeBailDetail/finOperateDetail";
  

// 当月经营费用账单表格的列
const allBillColumns = [
  {
    title: "费用类型",
    dataIndex: "typeName",
    key: "typeName",
  },
  {
    title: "账单周期",
    dataIndex: "cycle",
    scopedSlots: { customRender: "cycle" },
  },
  {
    title: "金额",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
];

// 经营费用账单记录表格的列
const allRecordColumns = [
  {
    title: "账单ID",
    dataIndex: "billCode",
    key: "billCode",
  },
  {
    title: "账单周期",
    dataIndex: "cycle",
    scopedSlots: { customRender: "cycle" },
  },
  {
    title: "账单状态",
    dataIndex: "billStatusName",
    key: "billStatusName",
  },
  {
    title: "支付状态",
    dataIndex: "payStatusName",
    key: "payStatusName",
  },
  {
    title: "账单总金额",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
  {
    title: "已支付金额",
    dataIndex: "payedAmount",
    key: "payedAmount",
  },
  {
    title: "已确认到账金额",
    dataIndex: "confirmArrivalAmount",
    key: "confirmArrivalAmount",
  },
  {
    title: "未支付金额",
    dataIndex: "needPayAmount",
    key: "needPayAmount",
  },
  {
    title: "未确认到账金额",
    dataIndex: "notConfirmArrivalAmount",
    key: "notConfirmArrivalAmount",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

let newOperateItem = {
  type: null, // 押金类型
  amount: null,
  remark: null,
};

export default {
  name: "SettlementDetailOperate",
  components: {
    DescriptionList,
    finOperateDetail,
  },
  props: {
    billId: {
      type: [String, Number],
    },
    // 合同id
    contractId: {
      type: [String, Number],
    },
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
  },
  data() {
    return {
      // 经营账单类型: actual - 实际公摊；fix - 固定管理
      operateBillType: "actual",
      // 当月经营费用表格数据
      operateList: [],
      // 当月经营费用表格和修改离场日的loading
      operateListLoading: false,
      // 经营费用账单状态，等于2时无法修改
      operateBillStatus: null,
      // 表格中的列。没有根据status去判断查看或编辑，通过隐藏action让查看态不可编辑
      billColumns:
        this.status === "view"
          ? allBillColumns.filter((c) => c.dataIndex !== "action")
          : allBillColumns,
      // 是否展示修改离场日modal
      showDateChangeModal: false,
      // 是否展示新建/编辑的经营费用
      showOperateModal: false,
      // 当前正在编辑的金额
      operateItem: newOperateItem,
      // 新增（true）/编辑（false）
      isNew: null,
      // 编辑金额时的表单
      operateForm: this.$form.createForm(this),
      // 经营费用种类
      operateTypeList: [],
      // 两位小数的正则
      decimalTwoDigit,
      // 历史记录表格的列
      recordColumns:
        this.status === "view"
          ? allRecordColumns.filter((c) => c.dataIndex !== "action")
          : allRecordColumns,
      // 历史记录表格数据
      recordList: [],
      // 编辑金额时的表单
      dateForm: this.$form.createForm(this),
      // 修改离场日的数据
      leaveDate: {},
      // 是否展示弹出式账单详情
      showBillDetail: false,
      // 弹出式帐单详情当前打开的账单id
      historyBillId: null,
      // 经营费用仍需缴纳金额
      amountNeed: null,
      // 当前经营费用账单的id
      operateBillId: null,
    };
  },
  methods: {
    moment,
    refetch() {
      this.operateListLoading = true;
      // 当月明细（如果为空，就说明是“实际公摊类”，否则就是“固定管理费类”）
      axios({
        url:   this.$baseUrl + "departure/getOperationBillDetail",
        method: "GET",
        params: {
          contractId: this.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          if (res.data.obj.splitCostType === 1) {
            this.operateBillType = "fix";
            this.operateBillId = res.data.obj.billId;
            this.operateBillStatus = res.data.obj.billStatus;
            this.operateList = [
              ...(res.data.obj.billCostList || []),
              {
                id: -1,
                typeName: "总计（元）",
                cycleStartTimeLg: null,
                cycleEndTimeLg: null,
                totalAmount: res.data.obj.billAmount,
                remark: null,
              },
            ];
            // 费用种类
            axios({
              url:   this.$baseUrl + "departure/cost/type/list",
              method: "GET",
              params: {
                type: 4, // 1-押金类费用；2-赔偿类费用；3-返还类费用; 4-经营费用类型
              },
            }).then((res) => {
              if (res.data.success) {
                this.operateTypeList = res.data.rows;
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
            // 结束日期和备注
            axios({
              url:   this.$baseUrl + "contract/getContractDate",
              method: "GET",
              params: {
                contractId: this.contractId,
                type: "actual_departure_date",
              },
            }).then((res) => {
              if (res.data.success) {
                this.leaveDate = res.data.obj;
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
          } else {
            this.operateBillType = "actual";
          }

          // 历史账单
          axios({
            url:   this.$baseUrl + "departure/historyBillList",
            method: "GET",
            params: {
              contractId: this.contractId,
              billType: 5, // 4-房租账单 5-经营费用账单
            },
          }).then((res) => {
            if (res.data.success) {
              this.recordList = [
                ...(res.data.obj.rows || []),
                {
                  billId: -1,
                  billCode: "总计（元）",
                  cycleStartTime: null,
                  cycleEndTime: null,
                  totalAmount: res.data.obj.totalAmount,
                  payedAmount: res.data.obj.totalPayedAmount,
                  confirmArrivalAmount: res.data.obj.totalConfirmArrivalAmount,
                  needPayAmount: res.data.obj.totalNeedPayAmount,
                  notConfirmArrivalAmount:
                    res.data.obj.totalNotConfirmArrivalAmount,
                },
              ];
              this.amountNeed = res.data.obj.totalNeedPayAmount;
            } else {
              this.$message.error(res.data.returnMsg);
            }
          });
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.operateListLoading = false;
      });
    },
    // 新增金额
    handleAdd() {
      this.isNew = true;
      this.showOperateModal = true;
    },
    // 编辑金额（目前没有）
    handleEdit(record) {
      this.operateItem = {
        // 从数据获取
      };
      this.isNew = false;
      this.showOperateModal = true;
    },
    // 删除费用记录
    handleDelete(record) {
      axios({
        url:   this.$baseUrl + "bill/cost/delete",
        method: "GET",
        params: {
          billCostId: record.id,
          isDeparture: 1, // 是否是离场调整 0否1是
        },
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("删除成功");
          this.refetch();
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    // 查看历史账单
    handleViewBill(record) {
      this.historyBillId = record.billId;
      this.showBillDetail = true;
    },
    // 提交费用项
    onSubmit() {
      this.$message.error("不支持修改经营费用账单");
      //  this.operateForm.validateFields((errors, values) => {
      //    if (!errors) {
      //      if (this.isNew) {
      //        axios({
      //                url:   this.$baseUrl + "bill/cost/create",
      //                method: "POST",
      //                data: {
      //          billId: Number(this.operateBillId),
      //                  cycleStartTime: this.operateList[0].cycleStartTimeLg,
      //                  cycleEndTime: this.operateList[0].cycleEndTimeLg,
      //                  type: values.type,
      //                  totalAmount: values.amount,
      //                  handleType: 2, // 调整方式 1减免2扣除
      //                  isDeparture: 1, // 是否是离场调整 0否1是
      //                  costRule: "departure_adjust", // 固定值
      //                  remark: values.remark,
      //        },
      //    }).then((res) => {
      //        if (res.data.success) {
      //          this.$message.success("添加成功");
      //          this.refetch();
      //        } else {
      //          this.$message.error(res.data.returnMsg);
      //        }
      //    });
      //      } else {
      //        // edit
      //      }
      //      this.showOperateModal = false;
      //    } else {
      //      return;
      //    }
      //  });
    },
    onCancel() {
      this.showOperateModal = false;
    },
    handleDateChange() {
      // 修改离场日期
      this.showDateChangeModal = true;
    },
    onSubmitDate() {
      this.dateForm.validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "contract/modifyDeparture",
            method: "POST",
            data: {
              id: this.contractId,
              actualDepartureDate: values.actualDepartureDate,
              remark: values.remark,
            },
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("修改成功");
              this.refetch();
            } else {
              this.$message.error(res.data.returnMsg);
            }
          });
          this.showDateChangeModal = false;
        }
      });
    },
    onCancelDate() {
      this.showDateChangeModal = false;
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (
        (record.billCode && record.billCode.includes("总计")) ||
        (record.typeName && record.typeName.includes("总计"))
      ) {
        return "total-bold";
      }
    },
    // 关闭弹出式账单详情
    onCloseBill() {
      this.refetch();
      this.showBillDetail = false;
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<style lang="less" scoped>
.field-panel {
  display: flex;
  align-items: center;

  .text {
    width: 300px;
    line-height: 40px;
  }
  .item {
    margin: 8px;
  }
}
.nob {
  border-radius: 0 !important;
}
.desc-title {
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
.table-wrapper {
  margin-left: 16px;
  margin-right: 16px;
}
.margin-top {
  margin-top: 16px;
}
.cannot-edit {
  color: orange;
  font-weight: bold;
  margin: 16px;
}
</style>