/*
  离场结算账单详情
  
  此组件有两个状态，根据路由判断如何渲染
    查看：/finance/settlementSheet/settlementDetailView
    调整：/finance/settlementSheet/settlementDetailEdit
*/
<template>
  <div>
    <div>
      <!-- 头部 -->
      <div style="margin: -45px -24px 0px">
        <a-card :bordered="false">
          <DescriptionList title="商户信息" size="large">
            <div style="margin-top: 16px">
              <Description term="门店编号">
                <label>{{ tenantInfo.facilityCode }}</label>
              </Description>
              <Description term="门店名称">
                <label>{{ tenantInfo.facilityName }}</label>
              </Description>
              <Description term="档口编号">
                <label>{{ tenantInfo.kitchenCode }}</label>
              </Description>
              <Description term="店铺名称">
                <label>{{ tenantInfo.tenantStoreName }}</label>
              </Description>
              <Description term="客户名称">
                <label>{{ tenantInfo.tenantName }}</label>
              </Description>
              <Description term="客户合同">
                <span class="blueColor">
                  <a @click="viewContractDetail">{{
                    tenantInfo.contractCode
                  }}</a>
                </span>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>
      <!-- tab切换 -->
      <div style="margin: -24px -24px 24px">
        <a-card
          style="width: 100%"
          :bordered="false"
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="(key) => onTabChange(key)"
        >
          <SettlementDetailDeposit
            v-if="activeTabKey === 'deposit'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <SettlementDetailBalance
            v-if="activeTabKey === 'balance'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <utilityBalanceTransactionHistory
            v-if="activeTabKey === 'utilityBalance'"
            :contractId="tenantInfo.contractId"
            :showSummaryDetail="true"
          />
          <SettlementDetailOperate
            v-if="activeTabKey === 'operate'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <SettlementDetailRent
            v-if="activeTabKey === 'rent'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <SettlementDetailCharges
            v-if="activeTabKey === 'settlement'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <SettlementDetailRefund
            v-if="activeTabKey === 'refund'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <SettlementDetailSummary
            v-if="activeTabKey === 'summary'"
            :status="status"
            :billId="queryInfo.id"
            :contractId="tenantInfo.contractId"
          />
          <div v-if="status === 'edit'" class="button-panel">
            <a-button
              v-if="!isLastTab"
              type="primary"
              style="margin-left: 12px"
              @click="nextTab"
              >下一步</a-button
            >
            <a-button
              v-if="!isFirstTab && activeTabKey !== 'summary'"
              @click="previousTab"
              >上一步</a-button
            >
            <a-popconfirm
              placement="topRight"
              title="确认发送这些数据么？"
              :disabled="hasSent"
              @confirm="confirmSendBill"
              okText="Yes"
              cancelText="No"
            >
              <a-button
                style="margin-left: 8px"
                type="primary"
                v-if="activeTabKey === 'summary'"
                :disabled="hasSent"
                :loading="loadingSend"
                :icon="hasSent ? 'check' : ''"
                >{{ hasSent? '已发送' : '发送账单'}}</a-button
              >
            </a-popconfirm>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasPermissions } from "@/utils/auth";
import { FacilityActions } from "@/utils/actions";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import SettlementDetailDeposit from "./settlementDetailDeposit";
import SettlementDetailBalance from "./settlementDetailBalance";
import SettlementDetailOperate from "./settlementDetailOperate";
import SettlementDetailRefund from "./settlementDetailRefund";
import SettlementDetailRent from "./settlementDetailRent";
import SettlementDetailSettlement from "./settlementDetailSettlement";
import SettlementDetailCharges from "./settlementDetailCharges";
import SettlementDetailSummary from "./settlementDetailSummary";
import utilityBalanceTransactionHistory from "../components/utilityBalanceTransactionHistory"

const tabs =
[
        {
          key: "deposit",
          tab: "押金类",
        },
        {
          key: "balance",
          tab: "商户余额",
        },
        {
          key: "utilityBalance",
          tab: "水电余额",
        },
        {
          key: "operate",
          tab: "经营费用账单",
        },
        {
          key: "rent",
          tab: "基础服务费账单",
        },
        {
          key: "settlement",
          tab: "离场相关费用",
        },
        {
          key: "refund",
          tab: "赔偿返还类",
        },
        {
          key: "summary",
          tab: "总计",
        },
      ];

export default {
  name: "settlementDetail",
  components: {
    DescriptionList,
    Description,
    SettlementDetailDeposit,
    SettlementDetailBalance,
    SettlementDetailOperate,
    SettlementDetailRefund,
    SettlementDetailRent,
    SettlementDetailCharges,
    SettlementDetailSummary,
    utilityBalanceTransactionHistory,
  },
  props: {},
  data() {
    return {
      // 路由获取
      queryInfo: {
        id: this.$route.query.id,
      },
      tenantInfo: {
        rentOutType: this.$route.query.rentOutType, // 这里是字符串
        facilityCode: this.$route.query.facilityCode,
        facilityName: this.$route.query.facilityName,
        kitchenCode: this.$route.query.kitchenCode,
        tenantName: this.$route.query.tenantName,
        tenantStoreName: this.$route.query.tenantStoreName,
        contractCode: this.$route.query.contractCode,
        contractId: this.$route.query.contractId,
      },
      // tab页，换铺离场时没有押金类、商户余额
      tabList: tabs.filter(
        (tab) =>
          this.$route.query.rentOutType !== '4' ||
          (tab.key !== "deposit" && tab.key !== "balance")
      ),
      // 当前展示的tab
      activeTabKey: this.$route.query.rentOutType === '4' ? "operate" : "deposit",
      // 页面状态，分为查看态和编辑态（调整）
      status: this.$route.path.endsWith("settlementDetailView")
        ? "view"
        : "edit",
      // 发送账单的loading
      loadingSend: false,
      // 是否已发送
      hasSent: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    isFirstTab() {
      const currentIndex = this.tabList.findIndex((t) => {
        return t.key === this.activeTabKey;
      });
      return currentIndex === 0;
    },
    isLastTab() {
      const currentIndex = this.tabList.findIndex((t) => {
        return t.key === this.activeTabKey;
      });
      return currentIndex === this.tabList.length - 1;
    },
  },
  filters: {},
  methods: {
    init() {
      // 初始化
    },
    onTabChange(key) {
      this.activeTabKey = key;
    },
    // 跳转到下一个tab
    nextTab() {
      const currentIndex = this.tabList.findIndex((t) => {
        return t.key === this.activeTabKey;
      });
      this.activeTabKey =
        currentIndex >= 0
          ? this.tabList[currentIndex + 1].key
          : this.tabList[0].key;
    },
    // 跳转到上一个tab
    previousTab() {
      const currentIndex = this.tabList.findIndex((t) => {
        return t.key === this.activeTabKey;
      });
      this.activeTabKey =
        currentIndex >= 0
          ? this.tabList[currentIndex - 1].key
          : this.tabList[0].key;
    },
    viewContractDetail() {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: this.tenantInfo.contractId },
      });
    },
    // 发送账单
    confirmSendBill() {
      this.loadingSend = true;
      axios({
        url:   this.$baseUrl + "departure/depatureBillSend",
        method: "PUT",
        data: [this.tenantInfo.contractId],
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("发送成功");
            this.hasSent = true;
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.loadingSend = false;
        })
        .catch((error) => {
          this.loadingSend = false;
          this.$message.error(error.message);
        });
    },
  },
};
</script>
<style lang="less" >
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.total-bold {
  font-weight: bold;
}
</style>