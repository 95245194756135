/*
    离场结算账单详情 - 离场相关费用tab
*/
<template>
  <div>
    <a-table
      rowKey="id"
      :columns="columns"
      :dataSource="settlementList"
      :pagination="false"
      :rowClassName="handleTableRowClass"
    >
      <div slot="action" slot-scope="text, record">
        <span v-if="record.id !== -1" class="blueColor">
        <a @click="handleEdit(record)">编辑</a>
        </span>
      </div>
    </a-table>
    <a-modal
      title="编辑"
      :width="450"
      :visible="showModal"
      @ok="onSubmit"
      @cancel="onCancel"
      destroyOnClose
    >
      <a-form :form="form" layout="inline" :labelCol="formLabelCol" :wrapperCol="formInputCol">
        <a-form-item label="费用类型">
          <div>{{settlementItem.typeName}}</div>
        </a-form-item>
        <a-form-item label="已收金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
                          'amount',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue: settlementItem.amount 
                          }
                        ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: settlementItem.remark 
              }
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import _get from "lodash/get";
import { decimalTwoDigit } from "@/utils/regex";
  

const allColumns = [
  {
    title: "费用类型",
    dataIndex: "typeName",
    key: "typeName",
  },
  {
    title: "应收金额（元）",
    dataIndex: "totalAmountYuan",
    key: "totalAmountYuan",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

let newSettlementItem = {
  id: null,
  typeId: null,
  amount: null,
  remark: null,
};

export default {
  name: "SettlementDetailSettlement",
  props: {
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    // 账单id
    billId: {
      type: [String, Number],
    },
    contractId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 表格数据
      settlementList: [],
      // 表格中的列。没有根据status去判断查看或编辑，通过隐藏action让查看态不可编辑
      columns:
        this.status === "view"
          ? allColumns.filter((c) => c.dataIndex !== "action")
          : allColumns,
      showModal: false,
      // 当前正在编辑的金额
      settlementItem: newSettlementItem,
      // 编辑金额时的表单
      form: this.$form.createForm(this),
      // 两位小数的正则
      decimalTwoDigit,
      // 表单label的样式
      formLabelCol: {
        style: {
          textAlign: "right",
          width: "110px",
          margin: "5px",
        },
      },
      // 表单input的样式
      formInputCol: {
        style: {
          width: "250px",
          margin: "5px",
        },
      },
    };
  },
  methods: {
    refetch() {
      // 离场费用列表
      axios({
        url:   this.$baseUrl + "departure/cost/charges/list",
        method: "GET",
        params: {
          billId: this.billId,
          contractId: this.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.settlementList = [
            ...(_get(res, "data.obj.billCosts") || []),
            {
              id: -1,
              typeName: "总计（元）",
              totalAmountYuan: _get(res, "data.obj.totalAmountYuan"),
              remark: "",
            },
          ];
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    // 编辑金额
    handleEdit(record) {
      this.settlementItem = {
        id: record.id,
        typeId: record.type,
        typeName: record.typeName,
        amount: record.totalAmountYuan,
        remark: record.remark,
      };
      this.isNew = false;
      this.showModal = true;
    },
    onSubmit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          if (this.isNew) {
            // new 调用api，成功之后刷新数据
          } else {
            // edit
            axios({
              url:   this.$baseUrl + "departure/cost/reference/modify",
              method: "POST",
              data: {
                ...this.settlementItem,
                ...values,
                contractId: this.contractId},
            }).then((res) => {
              if (res.data.success) {
                this.$message.success("添加成功");
                this.refetch();
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
          }
          this.showModal = false;
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.showModal = false;
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (record.typeName.includes("总计")) {
        return "total-bold";
      }
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<style scoped>
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.nob {
  border-radius: 0 !important;
}
</style>