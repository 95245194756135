/*
    离场结算账单详情 - 离场相关费用tab*/
<template>
  <div>
    <DescriptionList title="离场相关费用" size="large">
      <div class="button-panel">
        <a-button v-if="status !== 'view'" icon="plus" @click="handleAddCharges">新增</a-button>
      </div>
      <a-table
        rowKey="id"
        class="table-wrapper"
        :columns="chargesColumns"
        :dataSource="chargesList"
        :pagination="false"
        :rowClassName="handleTableRowClass"
      >
        <div slot="action" slot-scope="text, record">
          <span v-if="record.id !== -1" class="blueColor">
            <a-popconfirm
              placement="bottomRight"
              title="确认删除吗？"
              @confirm="handleDeleteCharges(record)"
              okText="Yes"
              cancelText="No"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
        </div>
      </a-table>
    </DescriptionList>

    <a-modal
      :title="`${isNew ? '新增' : '编辑'}${ '新增费用'}`"
      :width="450"
      :visible="showModal"
      @ok="onSubmit"
      @cancel="onCancel"
      destroyOnClose
    >
      <a-form :form="form" layout="inline" :labelCol="formLabelCol" :wrapperCol="formInputCol">
        <a-form-item label="费用类型">
          <a-select
            v-if="isNew"
            placeholder="选择一个选项"
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请输入类型' }],
                initialValue: chargesItem.type, 
              }
            ]"
          >
            <a-select-option
              v-for="type in (chargeTypeList)"
              :key="type.typeId"
            >{{ type.typeName }}</a-select-option>
          </a-select>
          <div v-else>{{chargesItem.type}}</div>
        </a-form-item>
        <a-form-item label="金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
                          'amount',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue: chargesItem.amount 
                          }
                        ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: chargesItem.remark 
              }
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import _get from "lodash/get";
  
import DescriptionList from "@/components/DescriptionList";
import { decimalTwoDigit } from "@/utils/regex";

// 赔偿类表格的列
const allChargesColumns = [
  {
    title: "费用类型",
    dataIndex: "typeName",
    key: "typeName",
  },
  {
    title: "赔偿金额（元）",
    dataIndex: "totalAmountYuan",
    key: "totalAmountYuan",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

let newChargesItem = {
  type: null,
  typeName: null,
  amount: null,
  remark: null,
};

export default {
  name: "SettlementDetailCharges",
  components: {
    DescriptionList,
  },
  props: {
    billId: {
      type: [String, Number],
    },
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    contractId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // data
      chargesList: [],
      chargesColumns:
        this.status === "view"
          ? allChargesColumns.filter((c) => c.dataIndex !== "action")
          : allChargesColumns,
      showModal: false,
      // 当前正在编辑的金额
      chargesItem: newChargesItem,
      // 新增（true）/编辑（false）
      isNew: null,
      // 编辑金额时的表单
      form: this.$form.createForm(this),
      // 赔偿类金额种类
      chargeTypeList: [],
      // 两位小数的正则
      decimalTwoDigit,
      // 表单label的样式
      formLabelCol: {
        style: {
          textAlign: "right",
          width: "110px",
          margin: "5px",
        },
      },
      // 表单input的样式
      formInputCol: {
        style: {
          width: "250px",
          margin: "5px",
        },
      },
    };
  },
  methods: {
    refetch() {
      // 赔偿类
      axios({
        url:   this.$baseUrl + "departure/cost/charges/list",
        method: "GET",
        params: {
          billId: this.billId,
          contractId: this.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.chargesList = [
            ...(_get(res, "data.obj.billCosts") || []),
            {
              id: -1,
              remark: "",
              totalAmountYuan: _get(res, "data.obj.totalAmountYuan"),
              typeName: "总计（元）",
            },
          ];
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });

    },
    // 新增赔偿类金额·
    handleAddCharges() {
      this.isNew = true;
      this.showModal = true;
    },

    // 删除赔偿类金额
    handleDeleteCharges(record) {
      axios({
        url:   this.$baseUrl + "departure/cost/charges/delete",
        method: "POST",
        data: {
          billCostId: record.id,
          contractId: this.contractId,
          typeId: record.type,
          billId: this.billId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("删除成功");
          this.refetch();
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    onSubmit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          if (this.isNew) {
              // 赔偿类
              axios({
                url:   this.$baseUrl + "departure/cost/charges/add",
                method: "POST",
                data: {
                  billId: this.billId,
                  typeId: values.type,
                  amount: values.amount,
                  remark: values.remark,
                  contractId: this.contractId,
                },
              }).then((res) => {
                if (res.data.success) {
                  this.$message.success("新增成功");
                  this.refetch();
                } else {
                  this.$message.error(res.data.returnMsg);
                }
              });
          } else {
            // edit 暂时不支持编辑
          }
          this.showModal = false;
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.showModal = false;
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (record.typeName.includes("总计")) {
        return "total-bold";
      }
    },
  },
  created() {
    // 赔偿金额种类
    axios({
      url:   this.$baseUrl + "departure/cost/type/list",
      method: "GET",
      params: {
        type: 5, // 1:押金类， 2:赔偿类， 3:返还类, 5, 离场费用相关
        contractId: this.contractId,
      },
    }).then((res) => {
      if (res.data.success) {
        this.chargeTypeList = res.data.rows;
      } else {
        this.$message.error(res.data.returnMsg);
      }
    });

    this.refetch();
  },
};
</script>

<style scoped>
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.nob {
  border-radius: 0 !important;
}
.table-wrapper {
  margin-left: 16px;
  margin-right: 16px;
}
</style>